/**
 * partials/helpers.sass
 */

.txt-center {
  text-align: center; }

.txt-right,
.txt-r {
  text-align: right; }

.txt-italic {
  font-style: italic; }
