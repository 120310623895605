/**
 * partials/shame.sass
 */

.suggestion-result {
  overflow: scroll;
  width: auto;
  @extend %col-sm-24;
  position: absolute;
  padding: 0;
  z-index: 300;
  top: 72px;
  left: 8px;
  right: 8px;
  max-height: 60vh;
  background: white;
  box-shadow: 0px 10px 40px rgba(189,204,212,1); }

.suggestion-result__item {
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;

  td {
    vertical-align: middle; }

  b {
    font-weight: 700; }

  small {
    display: block;
    font-size: 10px; }

  .medication {
    display: block; }

  .form {
    font-size: 0.8 * $font-size; } }

.highlight-word {
  background: transparent;
  color: $color-text-highlight; }

.transparentOnPrint {
	background-color: #FFF; }

.prescription-view {
  background-color: #E8EEF3;
  padding-top: 5px;
  img {
    max-width: 100px; }

  .regular-routes-days {
    color: #333;
    font-size: 18px;
    font-weight: bold; }

  .regular-route {
    color: #333;
    font-size: 14px;
    font-weight: bold;

    img {
      margin: 10px 0 0 0;
      max-width: 120px; } }

  .table {
    td {
      vertical-align: middle;
      color: #000; }

    .medication-row {
      font-size: 15px; }

    tr {
      border-right: none;
      border-left: none;

      &:first-child {
        border-top: none; } }

    tr:hover {
      background: inherit; }

    .table td {
      padding: 0;
      margin: 0; } }

  .routes-container {
    font-weight: bold;
    color: #333;
    padding: 10px 0; }

  .routes-days {
    font-size: 18px; }

  .routes {
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;

      img {
        max-width: 120px;
        margin: 10px 0 0 0; } } }

  .period {
    text-align: center;
    font-weight: bold;
    font-size: 18px;

    img {
      display: block;
      margin: 0 auto; } }

  .medication {
    tr {
      border: none; }

    .table td {
      padding: 8px 0;
      font-size: 15px; } }

  .amount-td {
    width: 200px; }

  .amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    font-weight: bold;
    font-size: 18px;

    img {
      margin: 0 10px;
      max-height: 35px; } } }

.prescription-view--header {
  margin-bottom: 15px; }

.prescription-header {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.prescription-header__title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }

.prescription-metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  .routes {
    font-weight: bold;
    color: #333; }

  .routes h1 {
    font-size: 20px; }

  .routes li {
    margin: 0;
    margin-right: 10px; }

  .routes img {
    width: 100px; }

  p {
    text-align: center; } }

.prescription-metadata__signature {
  position: relative;
  width: 280px;
  height: 130px;
  margin: 10px 0;
  border: 1px dashed black; }

.prescription-footer {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px dashed black; }

.amount-action {
	position: relative;

	.help-info {
		position: absolute;
		left: 23px;
		top: 0; }

	.help-info--text {
		width: 300px; } }

.page-with-padding-top {
  padding-top: 65px; }
