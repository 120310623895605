/**
 * partials/base.sass
 */

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-family: $font-base;
  font-size: $font-size;
  line-height: $font-line;
  color: $color-text; }

p {
  font-family: $font-text;
  line-height: $font-line; }

a {
  color: $color-link; }

strong {
  font-weight: 700; }

img {
  max-width: 100%;
  height: auto; }

.invisible {
  visibility: hidden; }

.s-alert-box {
	border-radius: 5px;
	opacity: .9; }

legend {
  font-weight: 700;
  color: #000; }


#root {
 }  // @extend %container-fluid
