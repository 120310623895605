.logo-overlay {
	position: relative;
	cursor: pointer;
}

.logo {
	opacity: 1;
	display: block;
	width: 100%;
	height: auto;
	-webkit-transition: 0.5s ease;
	transition: 0.5s ease;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.middle {
	-webkit-transition: 0.5s ease;
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.logo-overlay:hover .logo {
	opacity: 0.3;
}

.logo-overlay:hover .middle {
	opacity: 1;
}

.remove-button {
	background-color: #7c7ce7;
	color: #fff;
	font-size: 16px;
	padding: 16px 32px;
}

.upload {
	text-align: center;
	font-size: 16px;
}

.upload-icon {
	font-size: 40px;
}

.responsivity-alert-logged {
	display: none;
}

@media (max-width: 799px) {
	.responsivity-alert-logged {
		display: block;
		position: fixed;
		width: 100%;
		background: #6844d5;
		color: white;
		bottom: 0;
		left: 0;
		text-align: center;
		padding: 10px;
	}
}

.progress-btn {
	position: relative;
	width: 200px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	background-color: #8080ec;
	border-radius: 50px;
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 0.875rem;
	font-weight: bold;
}

.progress-btn.success,
.progress-btn.success:hover {
	background-color: #42c39b;
}

.progress-btn .icon {
	margin-right: 5px;
}

.progress-btn.success .text {
	position: relative;
}

.progress-btn:hover {
	background-color: #7171dd;
}

.progress-btn:disabled {
	cursor: pointer;
	opacity: 0.4;
}

.progress-btn:not(.active) {
	cursor: pointer;
}

.progress-btn .text {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	line-height: 40px;
	text-align: center;
	z-index: 10;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress-btn .progress {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0%;
	z-index: 5;
	background: #42c39b;
	opacity: 0;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}

.progress-btn.active:disabled {
	opacity: 1;
}

.progress-btn.active .progress {
	opacity: 1;
	-webkit-animation: progress-anim 10s forwards;
	        animation: progress-anim 10s forwards;
}

@-webkit-keyframes progress-anim {
	0% {
		width: 10%;
	}
	5% {
		width: 10%;
	}
	10% {
		width: 15%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 70%;
	}
	95% {
		width: 90%;
	}
	100% {
		width: 90%;
	}
}

@keyframes progress-anim {
	0% {
		width: 10%;
	}
	5% {
		width: 10%;
	}
	10% {
		width: 15%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 70%;
	}
	95% {
		width: 90%;
	}
	100% {
		width: 90%;
	}
}

/**
 * source/grid.sass
 */
/**
 * Import base library.
 */
/**
 * library/library.sass
 */
/**
 * library/settings/media.sass
 */
/**
 * Containers.
 */
/**
 * Media points.
 */
/**
 * Breakpoints.
 */
/**
 * library/settings/extensions.sass.
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * library/settings/extensions/bootstrap.sass
 */
/**
 * library/settings/colors.sass.
 */
/**
 * Descriptive colors from guideline.
 */
/**
 * Functional color variables.
 */
/**
 * library/settings/typography.sass
 */
/**
 * Font families.
 */
/**
 * Custom font families.
 */
/**
 * Functional variables.
 */
/**
 * Font size and attributes.
 */
/**
 * library/components/transitions.sass.
 */
/**
 * library/components/grid.scss
 */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 320px) {
    .container {
      width: 100%; } }
  @media (min-width: 750px) {
    .container {
      width: 100%; } }
  @media (min-width: 1170px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 0) {
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24 {
    float: left; }
  .col-xs-1 {
    width: 4.16667%; }
  .col-xs-2 {
    width: 8.33333%; }
  .col-xs-3 {
    width: 12.5%; }
  .col-xs-4 {
    width: 16.66667%; }
  .col-xs-5 {
    width: 20.83333%; }
  .col-xs-6 {
    width: 25%; }
  .col-xs-7 {
    width: 29.16667%; }
  .col-xs-8 {
    width: 33.33333%; }
  .col-xs-9 {
    width: 37.5%; }
  .col-xs-10 {
    width: 41.66667%; }
  .col-xs-11 {
    width: 45.83333%; }
  .col-xs-12 {
    width: 50%; }
  .col-xs-13 {
    width: 54.16667%; }
  .col-xs-14 {
    width: 58.33333%; }
  .col-xs-15 {
    width: 62.5%; }
  .col-xs-16 {
    width: 66.66667%; }
  .col-xs-17 {
    width: 70.83333%; }
  .col-xs-18 {
    width: 75%; }
  .col-xs-19 {
    width: 79.16667%; }
  .col-xs-20 {
    width: 83.33333%; }
  .col-xs-21 {
    width: 87.5%; }
  .col-xs-22 {
    width: 91.66667%; }
  .col-xs-23 {
    width: 95.83333%; }
  .col-xs-24 {
    width: 100%; } }

@media (min-width: 320px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
    float: left; }
  .col-sm-1 {
    width: 4.16667%; }
  .col-sm-2 {
    width: 8.33333%; }
  .col-sm-3 {
    width: 12.5%; }
  .col-sm-4 {
    width: 16.66667%; }
  .col-sm-5 {
    width: 20.83333%; }
  .col-sm-6 {
    width: 25%; }
  .col-sm-7 {
    width: 29.16667%; }
  .col-sm-8 {
    width: 33.33333%; }
  .col-sm-9 {
    width: 37.5%; }
  .col-sm-10 {
    width: 41.66667%; }
  .col-sm-11 {
    width: 45.83333%; }
  .col-sm-12 {
    width: 50%; }
  .col-sm-13 {
    width: 54.16667%; }
  .col-sm-14 {
    width: 58.33333%; }
  .col-sm-15 {
    width: 62.5%; }
  .col-sm-16 {
    width: 66.66667%; }
  .col-sm-17 {
    width: 70.83333%; }
  .col-sm-18 {
    width: 75%; }
  .col-sm-19 {
    width: 79.16667%; }
  .col-sm-20 {
    width: 83.33333%; }
  .col-sm-21 {
    width: 87.5%; }
  .col-sm-22 {
    width: 91.66667%; }
  .col-sm-23 {
    width: 95.83333%; }
  .col-sm-24 {
    width: 100%; } }

@media (min-width: 750px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24 {
    float: left; }
  .col-md-1 {
    width: 4.16667%; }
  .col-md-2 {
    width: 8.33333%; }
  .col-md-3 {
    width: 12.5%; }
  .col-md-4 {
    width: 16.66667%; }
  .col-md-5 {
    width: 20.83333%; }
  .col-md-6 {
    width: 25%; }
  .col-md-7 {
    width: 29.16667%; }
  .col-md-8 {
    width: 33.33333%; }
  .col-md-9 {
    width: 37.5%; }
  .col-md-10 {
    width: 41.66667%; }
  .col-md-11 {
    width: 45.83333%; }
  .col-md-12 {
    width: 50%; }
  .col-md-13 {
    width: 54.16667%; }
  .col-md-14 {
    width: 58.33333%; }
  .col-md-15 {
    width: 62.5%; }
  .col-md-16 {
    width: 66.66667%; }
  .col-md-17 {
    width: 70.83333%; }
  .col-md-18 {
    width: 75%; }
  .col-md-19 {
    width: 79.16667%; }
  .col-md-20 {
    width: 83.33333%; }
  .col-md-21 {
    width: 87.5%; }
  .col-md-22 {
    width: 91.66667%; }
  .col-md-23 {
    width: 95.83333%; }
  .col-md-24 {
    width: 100%; } }

@media (min-width: 1170px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
    float: left; }
  .col-lg-1 {
    width: 4.16667%; }
  .col-lg-2 {
    width: 8.33333%; }
  .col-lg-3 {
    width: 12.5%; }
  .col-lg-4 {
    width: 16.66667%; }
  .col-lg-5 {
    width: 20.83333%; }
  .col-lg-6 {
    width: 25%; }
  .col-lg-7 {
    width: 29.16667%; }
  .col-lg-8 {
    width: 33.33333%; }
  .col-lg-9 {
    width: 37.5%; }
  .col-lg-10 {
    width: 41.66667%; }
  .col-lg-11 {
    width: 45.83333%; }
  .col-lg-12 {
    width: 50%; }
  .col-lg-13 {
    width: 54.16667%; }
  .col-lg-14 {
    width: 58.33333%; }
  .col-lg-15 {
    width: 62.5%; }
  .col-lg-16 {
    width: 66.66667%; }
  .col-lg-17 {
    width: 70.83333%; }
  .col-lg-18 {
    width: 75%; }
  .col-lg-19 {
    width: 79.16667%; }
  .col-lg-20 {
    width: 83.33333%; }
  .col-lg-21 {
    width: 87.5%; }
  .col-lg-22 {
    width: 91.66667%; }
  .col-lg-23 {
    width: 95.83333%; }
  .col-lg-24 {
    width: 100%; } }

/**
 * library/components/page.sass
 */
/**
 * library/components/page-box-form.sass
 */
/**
 * partials/buttons.sass
 */
/**
 * Import partials.
 */
/**
 * partials/grid.sass
 */

/**
 * source/main.sass
 */
/**
 * Import base library.
 */
/**
 * library/library.sass
 */
/**
 * library/settings/media.sass
 */
/**
 * Containers.
 */
/**
 * Media points.
 */
/**
 * Breakpoints.
 */
/**
 * library/settings/extensions.sass.
 */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * library/settings/extensions/bootstrap.sass
 */
/**
 * library/settings/colors.sass.
 */
/**
 * Descriptive colors from guideline.
 */
/**
 * Functional color variables.
 */
/**
 * library/settings/typography.sass
 */
/**
 * Font families.
 */
/**
 * Custom font families.
 */
/**
 * Functional variables.
 */
/**
 * Font size and attributes.
 */
/**
 * library/components/transitions.sass.
 */
/**
 * library/components/grid.scss
 */
.suggestion-result {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 320px) {
  .suggestion-result {
    float: left; }
  .suggestion-result {
    width: 100%; } }

/**
 * library/components/page.sass
 */
/**
 * library/components/page-box-form.sass
 */
/**
 * partials/buttons.sass
 */
/**
 * Import partials.
 */
/**
 * partials/base.sass
 */
*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #686868; }

p {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: 1.6; }

a {
  color: #7C7CE4; }

strong {
  font-weight: 700; }

img {
  max-width: 100%;
  height: auto; }

.invisible {
  visibility: hidden; }

.s-alert-box {
  border-radius: 5px;
  opacity: .9; }

legend {
  font-weight: 700;
  color: #000; }

/**
 * partials/modal.sass
 */
.adv-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  background-color: #fafafa;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .adv-modal .title {
    font-size: 24px; }

.adv-modal-overlay {
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); }

.adv-modal--content {
  padding: 25px 25px 80px; }

.adv-modal--footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  background-color: #fafafa;
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  border-radius: 0 0 2px 2px; }

/**
 * partials/table.sass
 */
.table-responsive tr:hover {
  background: #fff; }

.table {
  width: 100%; }

tr {
  width: 100%;
  border: 1px solid #e0e0e0; }
  tr:hover {
    cursor: pointer; }

td {
  padding: 10px 20px;
  margin: 0; }

/**
 * partials/helpers.sass
 */
.txt-center {
  text-align: center; }

.txt-right,
.txt-r {
  text-align: right; }

.txt-italic {
  font-style: italic; }

/**
 * partials/shame.sass
 */
.suggestion-result {
  overflow: scroll;
  width: auto;
  position: absolute;
  padding: 0;
  z-index: 300;
  top: 72px;
  left: 8px;
  right: 8px;
  max-height: 60vh;
  background: white;
  box-shadow: 0px 10px 40px #bdccd4; }

.suggestion-result__item {
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px; }
  .suggestion-result__item td {
    vertical-align: middle; }
  .suggestion-result__item b {
    font-weight: 700; }
  .suggestion-result__item small {
    display: block;
    font-size: 10px; }
  .suggestion-result__item .medication {
    display: block; }
  .suggestion-result__item .form {
    font-size: 11.2px; }

.highlight-word {
  background: transparent;
  color: #a6a6ed; }

.transparentOnPrint {
  background-color: #FFF; }

.prescription-view {
  background-color: #E8EEF3;
  padding-top: 5px; }
  .prescription-view img {
    max-width: 100px; }
  .prescription-view .regular-routes-days {
    color: #333;
    font-size: 18px;
    font-weight: bold; }
  .prescription-view .regular-route {
    color: #333;
    font-size: 14px;
    font-weight: bold; }
    .prescription-view .regular-route img {
      margin: 10px 0 0 0;
      max-width: 120px; }
  .prescription-view .table td {
    vertical-align: middle;
    color: #000; }
  .prescription-view .table .medication-row {
    font-size: 15px; }
  .prescription-view .table tr {
    border-right: none;
    border-left: none; }
    .prescription-view .table tr:first-child {
      border-top: none; }
  .prescription-view .table tr:hover {
    background: inherit; }
  .prescription-view .table .table td {
    padding: 0;
    margin: 0; }
  .prescription-view .routes-container {
    font-weight: bold;
    color: #333;
    padding: 10px 0; }
  .prescription-view .routes-days {
    font-size: 18px; }
  .prescription-view .routes {
    list-style: none; }
    .prescription-view .routes li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px; }
      .prescription-view .routes li img {
        max-width: 120px;
        margin: 10px 0 0 0; }
  .prescription-view .period {
    text-align: center;
    font-weight: bold;
    font-size: 18px; }
    .prescription-view .period img {
      display: block;
      margin: 0 auto; }
  .prescription-view .medication tr {
    border: none; }
  .prescription-view .medication .table td {
    padding: 8px 0;
    font-size: 15px; }
  .prescription-view .amount-td {
    width: 200px; }
  .prescription-view .amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    font-weight: bold;
    font-size: 18px; }
    .prescription-view .amount img {
      margin: 0 10px;
      max-height: 35px; }

.prescription-view--header {
  margin-bottom: 15px; }

.prescription-header {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.prescription-header__title {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }

.prescription-metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0; }
  .prescription-metadata .routes {
    font-weight: bold;
    color: #333; }
  .prescription-metadata .routes h1 {
    font-size: 20px; }
  .prescription-metadata .routes li {
    margin: 0;
    margin-right: 10px; }
  .prescription-metadata .routes img {
    width: 100px; }
  .prescription-metadata p {
    text-align: center; }

.prescription-metadata__signature {
  position: relative;
  width: 280px;
  height: 130px;
  margin: 10px 0;
  border: 1px dashed black; }

.prescription-footer {
  padding-top: 10px;
  margin-top: 20px;
  border-top: 1px dashed black; }

.amount-action {
  position: relative; }
  .amount-action .help-info {
    position: absolute;
    left: 23px;
    top: 0; }
  .amount-action .help-info--text {
    width: 300px; }

.page-with-padding-top {
  padding-top: 65px; }

body {
  position: relative;
  min-height: 100%;
  padding-bottom: 76px; }

html {
  height: 100%; }

.watermark {
  display: none; }

@media print {
  @page {
    size: auto;
    margin: 0; }
  html {
    background: none;
    margin: 20px; }
  body {
    background: none;
    margin: 0;
    padding: 0;
    font-size: 10pt; }
  thead {
    display: table-header-group; }
  tr {
    page-break-inside: avoid; }
  .page {
    display: block; }
  .header,
  .sidebar,
  .internal-menu,
  .prescription-nav {
    display: none; }
  .box-form,
  .page-content__inner {
    width: 100%;
    box-shadow: none;
    padding: 0;
    background: none; }
  #headerBar {
    display: none !important; }
  .noPrint {
    display: none !important; }
  .transparentOnPrint {
    background-color: transparent; }
  .page-with-padding-top {
    padding-top: 0; }
  .prescription-view {
    background-color: transparent; }
  .watermark {
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: -1; }
    .watermark img {
      max-width: 90%; } }

