.progress-btn {
	position: relative;
	width: 200px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 0.4s ease;
	background-color: #8080ec;
	border-radius: 50px;
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 0.875rem;
	font-weight: bold;
}

.progress-btn.success,
.progress-btn.success:hover {
	background-color: #42c39b;
}

.progress-btn .icon {
	margin-right: 5px;
}

.progress-btn.success .text {
	position: relative;
}

.progress-btn:hover {
	background-color: #7171dd;
}

.progress-btn:disabled {
	cursor: pointer;
	opacity: 0.4;
}

.progress-btn:not(.active) {
	cursor: pointer;
}

.progress-btn .text {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	line-height: 40px;
	text-align: center;
	z-index: 10;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress-btn .progress {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0%;
	z-index: 5;
	background: #42c39b;
	opacity: 0;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}

.progress-btn.active:disabled {
	opacity: 1;
}

.progress-btn.active .progress {
	opacity: 1;
	animation: progress-anim 10s forwards;
}

@keyframes progress-anim {
	0% {
		width: 10%;
	}
	5% {
		width: 10%;
	}
	10% {
		width: 15%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 70%;
	}
	95% {
		width: 90%;
	}
	100% {
		width: 90%;
	}
}
