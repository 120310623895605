/**
 * library/settings/media.sass
 */

/**
 * Containers.
 */

$container-xs: 100%;
$container-sm: 100%;
$container-md: 100%;
$container-lg: 1170px;


/**
 * Media points.
 */

$sm-min: 320px;
$md-min: 750px;
$lg-min: 1170px;

$xs-max: $sm-min - 1;
$sm-max: $md-min - 1;
$md-max: $lg-min - 1;


/**
 * Breakpoints.
 */

$breakpoints: xs, sm, md, lg;

$breakpoint-xs: (width 0 $xs-max);
$breakpoint-sm: (width $sm-min $sm-max);
$breakpoint-md: (width $md-min $md-max);
$breakpoint-lg: (width $lg-min);

$breakpoint-min-xs: (0); // Shortcut to 'any'.
$breakpoint-min-sm: ($sm-min);
$breakpoint-min-md: ($md-min);
$breakpoint-min-lg: ($lg-min);

$breakpoint-max-xs: (max-width $xs-max);
$breakpoint-max-sm: (max-width $sm-max);
$breakpoint-max-md: (max-width $md-max);
$breakpoint-max-lg: (0); // Shortcut to 'any'.

$breakpoint-map:     ("xs": $breakpoint-xs,     "sm": $breakpoint-sm,     "md": $breakpoint-md,     "lg": $breakpoint-lg);
$breakpoint-min-map: ("xs": $breakpoint-min-xs, "sm": $breakpoint-min-sm, "md": $breakpoint-min-md, "lg": $breakpoint-min-lg);
$breakpoint-max-map: ("xs": $breakpoint-max-xs, "sm": $breakpoint-max-sm, "md": $breakpoint-max-md, "lg": $breakpoint-max-lg);
