/**
 * library/settings/typography.sass
 */


/**
 * Font families.
 */

$font-arial: Arial, Helvetica, sans-serif;


/**
 * Custom font families.
 */

$font-roboto: "Roboto", $font-arial;


/**
 * Functional variables.
 */

$font-base: $font-roboto;
$font-title: $font-roboto;
$font-text: $font-roboto;


/**
 * Font size and attributes.
 */

$font-size: 14px;
$font-line: 1.6;
