/**
 * source/main.sass
 */

/**
 * Import base library.
 */

@import "../library/library";


/**
 * Import partials.
 */

@import "../partials/base";
@import "../partials/modal";
@import "../partials/table";

@import "../partials/helpers";
@import "../partials/shame";

body {
  position: relative;
  min-height: 100%;
  padding-bottom: 76px; }

html {
  height: 100%; }

.watermark {
  display: none; }

@media print {
  @page {
    size: auto;
    margin: 0; }

  html {
    background: none;
    margin: 20px; }

  body {
    background: none;
    margin: 0;
    padding: 0;
    font-size: 10pt; }

  thead {
    display: table-header-group; }

  tr {
    page-break-inside: avoid; }

  .page {
    display: block; }

  .header,
  .sidebar,
  .internal-menu,
  .prescription-nav {
    display: none; }

  .box-form,
  .page-content__inner {
    width: 100%;
    box-shadow: none;
    padding: 0;
    background: none; }

  #headerBar {
    display: none !important; }

  .noPrint {
    display: none !important; }

  .transparentOnPrint {
    background-color: transparent; }

  .page-with-padding-top {
    padding-top: 0; }

  .prescription-view {
    background-color: transparent; }

  .watermark {
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: -1;
    img {
      max-width: 90%; } } }

