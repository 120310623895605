.logo-overlay {
	position: relative;
	cursor: pointer;
}

.logo {
	opacity: 1;
	display: block;
	width: 100%;
	height: auto;
	transition: 0.5s ease;
	backface-visibility: hidden;
}

.middle {
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.logo-overlay:hover .logo {
	opacity: 0.3;
}

.logo-overlay:hover .middle {
	opacity: 1;
}

.remove-button {
	background-color: #7c7ce7;
	color: #fff;
	font-size: 16px;
	padding: 16px 32px;
}

.upload {
	text-align: center;
	font-size: 16px;
}

.upload-icon {
	font-size: 40px;
}
