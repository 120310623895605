/**
 * library/components/grid.scss
 */

%container {
	@include container-fixed;

	@include breakpoint($breakpoint-min-sm) {
		width: $container-sm;
	}
	@include breakpoint($breakpoint-min-md) {
		width: $container-md;
	}
	@include breakpoint($breakpoint-min-lg) {
		width: $container-lg;
	}
}

%container-fluid {
	@include container-fixed;
}

%row {
	@include make-row;
}

%clearfix {
	@include clearfix;
}

%col {
	position: relative;
	min-height: 1px;
	padding-left: ceil(($grid-gutter-width / 2));
	padding-right: floor(($grid-gutter-width / 2));
}

@each $name, $media in $breakpoint-min-map {
	// 1. Extend default column attributes.
	@for $i from (1) through $grid-columns {
		%col-#{$name}-#{$i} {
			@extend %col;
		}
	}

	// 2. Build breakpointed grids.
	@include breakpoint($media) {
		%col-#{$name} {
			float: left;
		}

		@for $i from (0) through $grid-columns {
			$col-percentage: percentage(($i / $grid-columns));

			@if ($i > 0) {
				%col-#{$name}-#{$i} {
					// 2.1. Add floaters.
					@extend %col-#{$name};

					// 2.2. Add widths.
					width: $col-percentage;
				}
			}
		}
	}
}

@each $name, $media in $breakpoint-map {
	// 2. Build breakpointed grids.
	@include breakpoint($media) {
		@for $i from (0) through $grid-columns {
			$col-percentage: percentage(($i / $grid-columns));

			// 2.1. Add pushs and pulls.
			@each $type, $attr in ("push": "left", "pull": "right") {
				%col-#{$name}-#{$type}-#{$i} {
					@if ($i == 0) {
						#{$attr}: auto;
					} @else {
						#{$attr}: $col-percentage;
					}
				}
			}

			// 2.2. Add offsets.
			%col-#{$name}-offset-#{$i} {
				margin-left: $col-percentage;
			}
		}

		// 2.3. Bleeding options.
		%col-#{$name}-bleed-left {
			padding-left: 0;
		}

		%col-#{$name}-bleed-right {
			padding-right: 0;
		}

		// 2.4. Add new line option.
		%col-#{$name}-clear-left {
			clear: left;
		}

		// 2.5. Add hidden options.
		%hidden-#{$name} {
			display: none;
		}
	}
}
