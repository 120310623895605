.responsivity-alert-logged {
	display: none;
}

@media (max-width: 799px) {
	.responsivity-alert-logged {
		display: block;
		position: fixed;
		width: 100%;
		background: #6844d5;
		color: white;
		bottom: 0;
		left: 0;
		text-align: center;
		padding: 10px;
	}
}
