/**
 * partials/modal.sass
 */


.adv-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  background-color: $color-page-background-light;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  transform: translateY(-50%);

  .title {
    font-size: 24px; } }

.adv-modal-overlay {
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.5); }

.adv-modal--content {
  padding: 25px 25px 80px; }

.adv-modal--footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  background-color: $color-page-background-light;
  border-top: 1px dotted rgba(0,0,0,0.1);
  border-radius: 0 0 2px 2px; }
