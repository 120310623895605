/**
 * partials/table.sass
 */

.table-wrapper {}

.table-responsive {
  tr:hover {
    background: $color-page-background; } }

.table {
  width: 100%; }

tr {
  width: 100%;
  border: 1px solid $color-border-light;

  &:hover {
    cursor: pointer; } }

td {
  padding: 10px 20px;
  margin: 0; }
