/**
 * library/settings/colors.sass.
 */

/**
 * Descriptive colors from guideline.
 */

$color-lunar-green: #3E433E;
$color-doce-gray: #686868;
$color-gray: #808080;

$color-torch-red: #FF001F;
$color-havelock-blue: #4A90E2;
$color-sushi: #7CBB37;
$color-buttercup: #F5A623;

$color-brand-primary: #7C7CE4;
$color-brand-secondary: #28B7CA;
//$color-brand-primary-light: #1986b6
$color-brand-primary-dark: darken($color-brand-primary, 5%);
$color-brand-primary-light: lighten($color-brand-primary, 10%);
$color-brand-primary-lightest: lighten($color-brand-primary, 20%);

$color-danger: #C62828;

/**
 * Functional color variables.
 */
$color-page-background: #fff;
$color-page-background-light: #fafafa;
$color-page-background-dark: #bdccd4;

$color-title: $color-brand-primary;
$color-menu: $color-lunar-green;
$color-text: $color-doce-gray;
$color-text-highlight: $color-brand-primary-light;

$color-link: $color-brand-primary;

$color-button-background: $color-brand-primary;
$color-button-background-hover: $color-brand-primary-light;
$color-button-background-hover-dark: $color-brand-primary-dark;
$color-button-text: $color-brand-primary;
$color-button-danger: $color-danger;
$color-button-danger-hover: darken($color-danger, 5%);

$color-background: $color-brand-primary;

$color-border: #5a5a5a;
$color-border-active: $color-brand-primary-light;
$color-border-inactive: #ccc;
$color-border-light: #e0e0e0;

$color-shortcut-primary: $color-brand-secondary;

$color-form-readonly: $color-doce-gray;
